import jwt from "jsonwebtoken";
import api from "../api/api";
import publickey from "../api/publickey";

export const checkAuth = () => async (dispatch) => {
  try {
    if (localStorage.getItem("superadmin-auth")) {
      jwt.verify(
        localStorage.getItem("superadmin-auth"),
        publickey,
        async (err) => {
          if (!err) {
            return dispatch({
              type: "AUTH_SUPER_ADMIN",
            });
          } else {
            localStorage.clear();
            return dispatch({
              type: "AUTH_FAIL",
            });
          }
        }
      );
    } else if (localStorage.getItem("admin-auth")) {
      jwt.verify(localStorage.getItem("admin-auth"), publickey, async (err) => {
        if (!err) {
          return dispatch({
            type: "AUTH_ADMIN",
          });
        } else {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
      });
    } else if (localStorage.getItem("faculty-auth")) {
      jwt.verify(
        localStorage.getItem("faculty-auth"),
        publickey,
        async (err) => {
          if (!err) {
            return dispatch({
              type: "AUTH_FACULTY",
            });
          } else {
            localStorage.clear();
            return dispatch({
              type: "AUTH_FAIL",
            });
          }
        }
      );
    } else if (localStorage.getItem("student-auth")) {
      jwt.verify(
        localStorage.getItem("student-auth"),
        publickey,
        async (err) => {
          if (!err) {
            return dispatch({
              type: "AUTH_STUDENT",
            });
          } else {
            localStorage.clear();
            return dispatch({
              type: "AUTH_FAIL",
            });
          }
        }
      );
    } else if (localStorage.getItem("application-auth")) {
      jwt.verify(
        localStorage.getItem("application-auth"),
        publickey,
        async (err) => {
          if (!err) {
            return dispatch({
              type: "AUTH_APPLICATION",
            });
          } else {
            localStorage.clear();
            return dispatch({
              type: "AUTH_FAIL",
            });
          }
        }
      );
    } else {
      // localStorage.clear();
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  } catch (error) {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
};

export const fetchDecoded = () => (dispatch) => {
  let token;
  if (localStorage.getItem("superadmin-auth")) {
    token = localStorage.getItem("superadmin-auth");
  } else if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else if (localStorage.getItem("faculty-auth")) {
    token = localStorage.getItem("faculty-auth");
  } else if (localStorage.getItem("student-auth")) {
    token = localStorage.getItem("student-auth");
  } else if (localStorage.getItem("application-auth")) {
    token = localStorage.getItem("application-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, (err, decoded) => {
    if (!err) {
      try {
        return dispatch({
          type: "FETCH_DECODED",
          payload: decoded,
        });
      } catch (error) {
        return;
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};

export const fetchProfile = () => async (dispatch) => {
  let token;
  let profile;
  if (localStorage.getItem("superadmin-auth")) {
    token = localStorage.getItem("superadmin-auth");
    profile = "admin";
  } else if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
    profile = "admin";
  } else if (localStorage.getItem("faculty-auth")) {
    token = localStorage.getItem("faculty-auth");
    profile = "faculty/details";
  } else if (localStorage.getItem("student-auth")) {
    token = localStorage.getItem("student-auth");
    profile = "student";
  } else if (localStorage.getItem("application-auth")) {
    token = localStorage.getItem("application-auth");
    profile = "application";
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, async (err, decoded) => {
    if (!err) {
      try {
        const response = await api.get(`/api/${profile}/${decoded.id}`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        return dispatch({
          type: "FETCH_PROFILE",
          payload: response.data.data,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};

export const fetchDefault = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/default`);
    await Promise.all(
      dispatch({
        type: "FETCH_COLLEGE",
        payload: response.data.college,
      }),
      dispatch({
        type: "FETCH_BATCH",
        payload: response.data.batch,
      }),
      dispatch({
        type: "FETCH_PROGRAMME",
        payload: response.data.programme,
      }),
      dispatch({
        type: "FETCH_DEPARTMENT",
        payload: response.data.department,
      }),
      dispatch({
        type: "FETCH_SEMESTER",
        payload: response.data.semester,
      }),
      dispatch({
        type: "FETCH_CAT",
        payload: response.data.cat,
      }),
      dispatch({
        type: "FETCH_NEWS",
        payload: response.data.news,
      }),
      dispatch({
        type: "FETCH_CALENDAR",
        payload: response.data.calendar,
      })
    );
  } catch (error) {
    return;
  }
};

export const fetchCollegeDetails = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/college`);
    return dispatch({
      type: "FETCH_COLLEGE",
      payload: response.data.data,
    });
  } catch (error) {
    return;
  }
};

export const fetchBatch = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/batch`);
    return dispatch({
      type: "FETCH_BATCH",
      payload: response.data.data,
    });
  } catch (error) {
    return;
  }
};

export const fetchProgramme = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/programme`);
    return dispatch({
      type: "FETCH_PROGRAMME",
      payload: response.data.data,
    });
  } catch (error) {}
};

export const fetchDepartment = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/department`);
    return dispatch({
      type: "FETCH_DEPARTMENT",
      payload: response.data.data,
    });
  } catch (error) {}
};

export const fetchSemester = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/semester`);
    return dispatch({
      type: "FETCH_SEMESTER",
      payload: response.data.data,
    });
  } catch (error) {}
};

export const fetchCat = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/cat`);
    return dispatch({
      type: "FETCH_CAT",
      payload: response.data.data,
    });
  } catch (error) {}
};

export const fetchNews = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/news`);
    return dispatch({
      type: "FETCH_NEWS",
      payload: response.data.data,
    });
  } catch (error) {}
};

export const fetchCalendar = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/calendar`);
    return dispatch({
      type: "FETCH_CALENDAR",
      payload: response.data.data,
    });
  } catch (error) {
    return;
  }
};

export const fetchDashboardData = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("superadmin-auth")) {
    token = localStorage.getItem("superadmin-auth");
  } else if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else if (localStorage.getItem("faculty-auth")) {
    token = localStorage.getItem("faculty-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, async (err, decoded) => {
    if (!err) {
      try {
        const response = await api.get(`/api/dashboard`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        return dispatch({
          type: "FETCH_DASHBOARD",
          payload: response.data,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
        return dispatch({
          type: "CLEAR_DASHBOARD",
        });
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};
export const fetchNotice = () => async (dispatch) => {
  let token;
  let receiver;
  if (localStorage.getItem("faculty-auth")) {
    token = localStorage.getItem("faculty-auth");
    receiver = "Faculties";
  } else if (localStorage.getItem("student-auth")) {
    token = localStorage.getItem("student-auth");
    receiver = "Students";
  } else if (localStorage.getItem("parent-auth")) {
    token = localStorage.getItem("parent-auth");
    receiver = "Parents";
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, async (err, decoded) => {
    if (!err) {
      try {
        const response = await api.get(
          `/api/notification/${receiver}/${decoded.id}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        dispatch({
          type: "FETCH_READ_NOTICE",
          payload: response.data.read,
        });
        return dispatch({
          type: "FETCH_NOTICE",
          payload: response.data.data,
        });
      } catch (error) {
        dispatch({
          type: "CLEAR_NOTICE",
        });
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};
export const fetchAllNotification = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("superadmin-auth")) {
    token = localStorage.getItem("superadmin-auth");
  } else if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, async (err, decoded) => {
    if (!err) {
      try {
        const response = await api.get(`/api/notification`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        return dispatch({
          type: "FETCH_NOTICE",
          payload: response.data.data,
        });
      } catch (error) {
        dispatch({
          type: "CLEAR_NOTICE",
        });
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};

export const fetchGrievances = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("superadmin-auth")) {
    token = localStorage.getItem("superadmin-auth");
  } else if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  jwt.verify(token, publickey, async (err, decoded) => {
    if (!err) {
      try {
        const response = await api.get(`/api/grievance`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        return dispatch({
          type: "FETCH_GRIEVANCE",
          payload: response.data.data,
        });
      } catch (error) {
        dispatch({
          type: "CLEAR_GRIEVANCE",
        });
        if (error.response && error.response.status === 401) {
          localStorage.clear();
          return dispatch({
            type: "AUTH_FAIL",
          });
        }
      }
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  });
};

export const logout = () => async (dispatch) => {
  window.location.href = "/";
  localStorage.clear();
  return dispatch({
    type: "AUTH_FAIL",
  });
};

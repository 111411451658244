import { createTheme } from "@material-ui/core/styles";
import { amber } from "@material-ui/core/colors";
const theme = createTheme({
  palette: {
    primary: {
      main: "#2B7EB1",
    },
    secondary: {
      main: "#FF661F",
    },
    error: {
      main: "#C41E24",
    },
    background: {
      default: "#ffffff",
    },
    warning: {
      main: amber[500],
    },
    success: {
      main: "#1D911D",
    },
  },
  typography: {
    fontSize: 11,
  },
  overrides: {
    MuiTypography: {
      root: {
        fontSize: 11,
      },
    },
    MuiSelect: {
      root: {
        fontSize: 12,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 12,
      },
    },

    MuiInputBase: {
      root: {
        height: 30,
      },
      multiline: {
        height: "100%",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 12,
        marginTop: -3,
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        height: 30,
        fontSize: 12,
      },
    },
  },
});
export default theme;

//black #535150
//primary dal #CA7DF9
//blue ang chi #6161F3
